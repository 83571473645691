footer {
  background-color: #333;
  color: #808080;

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    color: white;
    text-decoration: underline;
  }
}
