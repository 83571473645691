#Pick-Answer .qa-context {
  font-size: 20px;
  min-height: 100px;
  white-space: pre-wrap;
}

#Pick-Answer .help-btn {
  font-size: 18px;
  position: relative;
  left: 6px;
  top: -3px;
  cursor: pointer;
}
