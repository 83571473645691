#QG-App .small-text {
  font-size: 10px;
}

#QG-App .giticon {
  position: relative;
  top: -1px;
  right: 2px;
}

#QG-App .subtitle {
  position: relative;
  top: -8px;
  color: #808080;
}

/* #QG-App h5{
  font-family: 'Vollkorn SC', serif;
  font-size: 11px;
  font-weight: 580;
  margin-top: -22px;
  margin-bottom: 12px;  
  letter-spacing: 6px;
} */

#QG-App h5 a {
  color: black;
}

#QG-App h5 a:hover {
  color: black;
  text-decoration: none;
}
